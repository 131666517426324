body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar-shadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


input.is-invalid,
input.is-invalid:hover,
input.is-invalid:active,
input.is-invalid:focus {
    background-color: #ffcccc;
    color: #dc3545;
}

input.is-invalid::-webkit-input-placeholder
{
    /* background-color: #ffcccc; */
    color: #dc3545 !important;
    font-weight: bold;
}

input.is-invalid:-ms-input-placeholder
{
    /* background-color: #ffcccc; */
    color: #dc3545 !important;
    font-weight: bold;
}

input.is-invalid::placeholder
{
    /* background-color: #ffcccc; */
    color: #dc3545 !important;
    font-weight: bold;
}

 /********************************/
/********** Table ***************/
table.app-responsive thead th{
  padding: 5px 5px 5px 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #65686B;
}

table.app-responsive tbody tr:nth-child(odd) {
  background-color: #F8F8F8;
}

table.app-responsive tbody td {
  padding: 5px 5px 5px 5px;

			/* font-family: 'Archivo'; */
			font-style: normal;
			font-weight: 400;
			font-size: 14px;

			color: #65686B;
}

table.app-responsive tbody tr:hover td {
  background-color: #ccc;
  cursor:pointer;
}
/*
	Max width before this PARTICULAR table gets nasty. 
	This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {

	table.app-responsive thead,
  table.app-responsive tbody,
  table.app-responsive th,
  table.app-responsive td,
  table.app-responsive tr {
			display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	table.app-responsive thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
	}

  table.app-responsive tr {
    margin: 0 0 1rem 0;
  }

  table.app-responsive tr:nth-child(odd) {
    background-color: #F8F8F8;
  }

  table.app-responsive td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50% !important;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
  }

  table.app-responsive td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding: 5px 5px 5px 5px;
    padding-right: 10px;
    white-space: nowrap;
  }

	table.app-responsive td:nth-of-type(even):before, 
  td:nth-of-type(odd):before {
		content: attr(data-title);
			font-weight: bold;
	}
	
}

/***********************************/
/******* Card ********/
.card-no-border {
    border-style: none !important;
    border-width: 0px !important;
}


/*******************************************/
/*********** Custom font size *************/
.f-1rem {
  font-size: 0.9rem;
}